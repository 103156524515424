@import "./palette.scss";

.header {
    width: 100vw;
    height: 10vh;
    background: rgba($color: #062237, $alpha: 0.5);
    background-blend-mode: multiply;
    position: relative;

    display: grid;
    grid-template-columns: 5% 25% 68% 2%;
    grid-template-rows: 100%;

    font-family: "Futura";
    letter-spacing: 1px;
    color: rgba($color: #ffffff, $alpha: 1.0);
    filter: saturate(2);

    z-index: 10 !important;

    .title {
        grid-column: 2/3;
        grid-row: 1/2;

        display: flex;
        justify-content: flex-start;
        align-items: center;
        letter-spacing: 4px;
    }

    .menu {
        grid-column: 3/4;
        grid-row: 1/2;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        // margin-top: 10px;

        ul {
            width: 50%;
            height: 100%;
            list-style-type: none;
            display: flex;
            justify-content: space-between;
            align-items: center;

            
            li {
                // transition: all ease 1s;
                font-size: 1.2rem;

            }

            div {
                width: 1px;
                height: 30%;
                background-color: rgba($color: #ffffff, $alpha: 1.0);
            }
        }
    }

    .linkStyle{
        cursor: pointer;

        &:hover {
            color: rgba($color: $celadon, $alpha: 1.0);
        }
    }

    .cinemate:hover {
        color: rgba($color: $periwinkle, $alpha: 1.0);
    }

    .quote:hover {
        color: rgba($color: $mint-green, $alpha: 1.0)
    }
}