//Main page -- LIMES
$dark-green: rgba(20, 52, 43, 1);
$sea-green: rgb(74, 143, 98, 1);
$celadon: rgb(169, 239, 175, 1);
$dutch-white: rgba(250, 237, 202, 1);
$imperial-red: rgba(241, 81, 82, 1);

//Cinemate page
$outer-space: rgba(75, 83, 87, 1);
$tekhelet: rgba(84, 51, 121, 1);
$tropical-indigo: rgba(157, 141, 241, 1);
$periwinkle: rgba(184, 205, 248, 1);
$amaranth-purple: rgba(165, 19, 82, 1);

//X page
$rose-pompadour: rgba(226, 115, 150, 1);
$amaranth-pink: rgba(234, 154, 178, 1);
$mimi-pink: rgba(239, 207, 227, 1);
$charcoal: rgba(38, 70, 83, 1);
$persian-green: rgba(42, 157, 143, 1);

//Drinks page ??
$raisin-black: rgba(46, 31, 39, 1);
$barn-red: rgba(112, 27, 10, 1);
$cocoa-brown: rgba(221, 114, 48, 1);
$saffron: rgba(244, 201, 93, 1);
$flax: rgba(231, 227, 147, 1);

//Quotes page 
$indigo-dye: rgba(8, 75, 131, 1);
$aero: rgba(66, 191, 221, 1);
$mint-green: rgba(187, 230, 228, 1);
$antiflash-white: rgba(240, 246, 246, 1);
$hot-pink: rgba(255, 102, 179, 1);