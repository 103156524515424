@import "./palette.scss";

.landing {
    position: relative;
    width: 100vw;
    height: 100vh;

    // background-image: url("../../public/Sun-rays.png");
    // background-repeat: no-repeat;
    // background-size: cover;
    margin-top: -10vh;

    background-color: $celadon;

    // opacity: 0.5;

    z-index: -1;
}