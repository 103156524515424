@import "./palette.scss";

.cineMate {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    font-family: "Futura";
    letter-spacing: 1px;
    background-color: $tropical-indigo;

    position: relative;
    width: 100vw;
    height: 100vh;
    margin-top: -10vh;
    padding-top: 10vh;
    
    .lists {
        margin: 10px;
        height: 400px;
        width: 380px;
        border-radius: 10px;

        display: flex;
        flex-direction: column;

        transition: all ease 0.25s;
        cursor: pointer;

        &:hover {
            transform: scale(1.01);
        }

        .image {
            height: 55%;
            width: 100%;
        }

        .title {
            height: 25%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            margin-bottom: 10px;
            color: $amaranth-purple;

        }

        .line {
            height: 1px;
            width: 80%;
            background-color: rgb(187, 187, 187);
            opacity: 0.6;
            display: flex;
            justify-self: center;
            align-self: center;
        }

        .content {
            height: 20%;
            width: 80%;
            margin: 0% 10%;
            margin-top: 20px;
            display: flex;
            align-items: flex-start;
            span{
                color: rgb(112, 112, 112);
                text-align: center;
            }
        }
    }

    .movielist {

        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 10vh auto;
        width: 100vw;
        height: fit-content;

        .menu {
            grid-column: 1/2;
            grid-row: 1/2;
            justify-self: center;
            display: flex;
            width: 30%;
            justify-content: space-between;
            align-items: center;

            button {
                height: fit-content;
                width: fit-content;
                padding: 0.7rem 1rem;
                border-radius: 15px;
                border: none;

                font-family: "TW Cen MT", sans-serif;
                font-size: 1.1rem;

                transition: all ease 0.25s;
                cursor: pointer;

                &:hover {
                    transform: scale(1.02);
                }
            }
        }

        .movies {
            grid-column: 1/2;
            grid-row: 2/3;
        }
    }

}